import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Privacy from './components/Privacy';

function App() {
  return (
    <Router>
    <div className="App">
      {/* <Header></Header> */}
      <div>
            {/* <Route exact path="/" component={Home} /> */}
            <Route path="/privacy" component={Privacy} />
        </div>
    </div>
    </Router>
  );
}

export default App;

import React, { Component } from 'react';

class Privacy extends Component {
    render() {
        return (
            <div>
                <h1>Privacy Policy</h1>
                <p>Privacy was one of the drivers for the auth app.
                    Only information Apple provides to developers is collected.
                <br />In order to use the iCloud sync feature your device must be signed into an
                    iCloud account and therefore are bound by the iCloud privacy policy.</p>
            </div>
        )
    }
}
export default Privacy;
import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div>
                <h1>Auth App</h1>
                <p>Home</p>
            </div>
        )
    }
};
export default Home;